<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('currencies_convert.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('currency_exchange.add_currency_exchange') }}
            </button>
        </div>


        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('currencies_convert.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
<!--                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('currencies_convert.delete')" @click="deleteAction(props.row)">mdi-delete</v-icon>-->
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('currency_exchange.currency_exchange')">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <label>{{$t('currency_exchange.system_currency')}} : </label>
              <span> <b> {{ currency_name }} </b></span>
              <!-- <select name="" id="currency_id" v-model="data.currency_id_from" class="custom-select" :class="validation && validation.currency_id_from ? 'is-invalid' : ''">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select> -->
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{$t('currency_exchange.currency')}}<span class="text-danger">*</span></label>
              <select name="" id="currency_id" v-model="data.currency_id_from" class="custom-select" :class="validation && validation.currency_id_from ? 'is-invalid' : ''">
                <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
              <span v-if="validation && validation.currency_id_from" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.currency_id_from[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label for="standard_val">
                {{ $t('currency_exchange.standard_val') }}
              </label>
              <input v-model="data.standard_val" type="number" min="0" id="standard_val" class="form-control" :class="validation && validation.standard_val ? 'is-invalid' : ''">
              <span v-if="validation && validation.standard_val" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.standard_val[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label for="custom_val">
                {{ $t('currency_exchange.custom_val') }}
              </label>
              <input v-model="data.custom_val" type="number"  min="0" id="custom_val" class="form-control" :class="validation && validation.custom_val ? 'is-invalid' : ''">
              <span v-if="validation && validation.custom_val" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.custom_val[0] }}
                </span>
            </div>

          </div>


            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                data: {
                    currency_code: null,
                    currency_id_to: null,
                    currency_id_from: null,
                    standard_val: null,
                    custom_val: null,
                },
                currency_name: null,
                filters: {},
                columns: ['currency_name', 'standard_val', 'custom_val', 'actions'],
                validation: null,
                mainRoute: 'settings/currencies-convert',
                mainRouteDependency: 'base/dependency',
                isEditing: false,
                id: null,
                currencies: [],
              out_currency_id: null,
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        currency_name: that.$t('currency_exchange.currency'),
                        standard_val: that.$t('currency_exchange.standard_val'),
                        custom_val: that.$t('currency_exchange.custom_val'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },
        watch:{
            'data.currency_id_from':  function (val) {
                if (val){
                    this.getData(val);
                }
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.currency_exchange")}]);
          let promise1 = this.getCurrencyDefault();
          Promise.all([promise1]).then(()=>{
            this.getCurrencies();
          });

        },
        methods: {

            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },
            getFetch() {
                this.$refs.table.refresh();
            },
            // actionDelete(item) {
            //
            //     ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
            //         this.getFetch();
            //         this.$successAlert(response.data.message)
            //     }).catch(({errors}) => {
            //         console.error(errors);
            //     })
            // },
            // delete(item) {
            //     this.$confirmAlert('', this.actionDelete, item);
            // },

            showModal(data) {
                this.validation = null;
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.data.currency_id_from = data.currency_id_from
                    this.$refs['modal'].show();
                }
            },
            hideModal() {
                this.reset();
                this.$refs['modal'].hide()
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            create() {
                if(this.currency_name != ''){
                    ApiService.post(this.mainRoute, this.data).then((response) => {
                        this.$successAlert(response.data.message);
                        this.afterSave();
                    }).catch((errors) => {
                        this.validation = errors.response ? errors.response.data.errors : null;
                    });
                }else{
                    this.$warningMessage(this.$t('currency_exchange.cannot_added_currency_conversion'),7000);
                }
            },
            update() {
                ApiService.put(`${this.mainRoute}/${this.id}`, {
                    ...this.data,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.afterSave();
                })
                    .catch((errors) => {
                        this.validation = errors.response ? errors.response.data.errors : null;
                    });
            },
            getData(id) {
                this.reset(false);
                ApiService.get(this.mainRoute + "/"+id).then((response) => {
                    if(response.data.data.id){
                        this.isEditing = true;
                        this.id = response.data.data.id;
                        this.data.currency_code = response.data.data.currency_code;
                        this.data.currency_id_to = response.data.data.currency_id_to;
                        this.data.standard_val = response.data.data.standard_val;
                        this.data.custom_val = response.data.data.custom_val;
                        if(response.data.data.currency_id_from && !this.data.currency_id_from){
                            this.data.currency_id_from = response.data.data.currency_id_from;
                        }
                    }

                });

            },

            reset(reset_from = true) {
                this.data.currency_code = null;
                this.data.currency_id_to = null;
                this.data.standard_val = null;
                this.data.custom_val = null;

                if (reset_from)
                    this.data.currency_id_from = null;

                this.isEditing = false;
                this.id = null;
                this.validation = [];
            },

            afterSave() {
                this.data.currency_code = null;
                this.data.currency_id_to = null;
                this.data.standard_val = null;
                this.data.custom_val = null;
                this.data.currency_id_from = null;

                this.isEditing = false;
                this.id = null;
                this.validation = [];
                this.hideModal();
                this.getFetch();
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data.filter(row => this.out_currency_id != row.id);
                });
            },
            async getCurrencyDefault() {
                await ApiService.get("/getUserPersonal").then((response) => {
                    this.currency_name = response.data.data.currency_name ??  null;
                    this.out_currency_id = response.data.data.currency_id ??  null;

                });
            },
        },
    };
</script>
